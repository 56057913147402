.starting-node-container {
  width: 250px;
  border: 1px solid #555;
  border-radius: 15px;
  background: lighten(#2ecc71, 20%);
  padding: 1px;

  .starting-node-label-container {
    padding: 10px;
  }

  .starting-node-inner-container {
    // height: 30px;
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 15px;
    background-color: #efefef;
    padding: 20px;
  }
}

.node-selected {
  border: 2px solid #e74c3c;
}
