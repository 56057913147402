.workflow-logs-container {
}

.workflow-item {
  cursor: pointer;
  padding: 12px 16px;
  transition: background 0.2s ease-in-out;
  border-radius: 8px;
}

.workflow-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.workflow-title {
  font-weight: 600;
  color: #1890ff;
}
