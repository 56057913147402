.general-node-container {
  width: 250px;
  border: 1px solid #555;
  border-radius: 15px;
  background-color: #fff;
  padding: 1px;

  .general-node-label-container {
    padding: 10px;
  }

  .general-node-inner-container {
    // height: 30px;
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 15px;
    background-color: #efefef;
    padding: 20px;
  }
}
