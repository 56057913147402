@import "../../../../../styles/variables.scss";

.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 0px 16px 10px;

  .chat--header {
    .feather-chevron-left {
      cursor: pointer;
    }
  }

  .chat--body {
    height: 100%;
    max-height: calc(100vh - 188px);
    flex: 2;
    overflow-y: auto;

    .msgs-container {
      .msg {
        align-items: flex-start;
        gap: 10px;
        padding: 8px;

        .msg-content {
        }

        .msg-timestamp {
          font-size: 0.9em;
        }
      }
      span {
        white-space: pre-line;
      }

      .msg-type--incoming {
        background-color: $secondary-background-color;
        border-radius: 5px;
      }

      .msg-type--outgoing {
      }

      .ant-avatar-string {
        display: flex;
      }
    }
  }

  .chat--footer {
    .input-container {
      width: 100%;
    }
  }
}
