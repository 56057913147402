.spending-limit-warning {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;

  .spending-alert-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .spending-progress {
    margin-top: 8px;
  }

  .ant-alert-message {
    width: 100%;
  }
}
