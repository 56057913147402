@import "../../../../../styles/variables.scss";

.toolbar-container {
  .readonly-workflow-title-container {
    padding: 5px 6px;
    transition: all 0.3s;
    border-radius: 4px;
  }
  .readonly-workflow-title-container:hover {
    background-color: lighten($primary-color-6, 5%);
  }
}
